import React from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import img1 from "../../assets/blog1img.png";
import img2 from "../../assets/blog1img2.png";
import img3 from "../../assets/blog1img3.png";
import img4 from "../../assets/blog1img4.png";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
const Blog1 = () => {
  return (
    <>
      <div style={{ backgroundColor: "#FFF8F0" }}>
        <Container maxWidth="xl">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                Shop by Category
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "85%",
                }}
              />
            </div>
          </div>
          <div
            className="mt-5"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img1} />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ zIndex: 1, height: 50 }}
                    className="mt-auto primary-btn-color"
                    variant="contained"
                  >
                    Luxury Floor
                  </Button>
                </div>
              </Card>
            </div>
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img2} />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ zIndex: 1, height: 50 }}
                    className="mt-auto primary-btn-color"
                    variant="contained"
                  >
                    Innovation Floor
                  </Button>
                </div>
              </Card>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                VR Showroom
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "95%",
                }}
              />
            </div>
          </div>
          <div
            className="mt-5"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img3} />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ zIndex: 1, height: 50 }}
                    className="mt-auto primary-btn-color"
                    variant="contained"
                  >
                    VR Showroom
                  </Button>
                </div>
              </Card>
            </div>
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img4} />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ zIndex: 1, height: 50 }}
                    className="mt-auto primary-btn-color"
                    variant="contained"
                  >
                    VR Showroom
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Blog1;
