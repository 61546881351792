export const checkDecimal = (number) => {
    let num = Math.floor(number);
    if (Number(number) === num) {
        return num;
    } else {
        return number;
    }
};

export const numberWithCommas = (x) => {
    if (
        x !== undefined &&
        x !== null &&
        x !== "" &&
        x !== "undefined" &&
        x !== "null"
    ) {
        return checkDecimal(x)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
};