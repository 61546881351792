import "./App.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResponsiveAppBar from "./components/navbar";
import Home from "./views/home/home";
import Footer from "./components/footer";
import Portfolio from "./views/portfolio";
import Contact from "./views/contact";
import Catalogue from "./views/catalogue";
import Ultra from "./views/ultra";
import Hybrid from "./views/hybrid";
import ContactUs from "./views/contactUs";
import PortSeeMore from "./views/portfolio/seemore";
import "./scss/global.scss";
import SeemoreBlog4 from "./views/home/seemoreBlog4";
import Seemore2Blog4 from "./views/home/seemore2Blog4";
import JournalsAndBlogs from "./views/admin/journalsandblogs";
import AdminHome from "./views/admin/home";
import JournalsAndBlogsAdd from "./views/admin/journalsandblogs/add";
import JournalsAndBlogsAddSeeMore from "./views/admin/journalsandblogs/seemore";
import ProductPage from "./views/admin/product";
import ProductAdd from "./views/admin/product/add";
import "sweetalert2/src/sweetalert2.scss";
import Login from "./views/admin/login";
import ContactPage from "./views/admin/customer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ResponsiveAppBar />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="portfolio/portseemore" element={<PortSeeMore />} />
            <Route path="contact" element={<Contact />} />
            <Route path="catalogue" element={<Catalogue />} />
            <Route path="ultra-engineered" element={<Ultra />} />
            <Route path="hybrid-engineered" element={<Hybrid />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="seemoreBlog4" element={<SeemoreBlog4 />} />
            <Route path="seemore2Blog4" element={<Seemore2Blog4 />} />
            <Route path="login" element={<Login />} />
            <Route path="o2e-admin" element={<AdminHome />} />
            <Route
              path="o2e-admin/journalsandblogs"
              element={<JournalsAndBlogs />}
            />
            <Route
              path="o2e-admin/journalsandblogs/add"
              element={<JournalsAndBlogsAdd />}
            />
            <Route
              path="o2e-admin/journalsandblogs/seemore"
              element={<JournalsAndBlogsAddSeeMore />}
            />
            <Route path="o2e-admin/product" element={<ProductPage />} />
            <Route path="o2e-admin/product/add" element={<ProductAdd />} />

            <Route path="o2e-admin/contact" element={<ContactPage />} />
            {/*<Route path="contact" element={<Contact />} />*/}
            {/*<Route path="*" element={<NoPage />} />*/}
          </Route>
        </Routes>
      </BrowserRouter>
      <div style={{ marginTop: 50 }} />
      <Footer />
    </div>
  );
}

export default App;
