import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Hybrid from "../../assets/hybrid.png";
import HybridSize from "../../assets/hybridSize.png";
import Advantages03 from "../../assets/advantages03.png";
import Advantages02 from "../../assets/advantages02.png";
import Advantages01 from "../../assets/advantages01.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import apiProduct from "../../api/product";

const HybridPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await apiProduct.getData();
    setData(res.data.elements);
  };

  const goTo = (path) => {
    navigate(path);
  };

  return (
    <>
      <Box sx={{ pl: "129px", pt: "30px" }}>
        <Box sx={{ pb: "20px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "38.09px",
              color: "#333333",
              // textAlign: "left !important",
              pb: "7px",
              fontWeight: "600",
            }}
          >
            HYBRID ENGINEERED
          </Typography>
          <Box
            sx={{
              background: "#AF9571",
              height: "3px",
              width: "234px",
              m: "0 auto",
              borderRadius: "2px",
            }}
          ></Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ width: "60%", display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "50%", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #004994",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#004994",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    1
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  A transparent, wear-resistant overlay
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  This top layer makes your Quick-Step Hybrid Engineered floor
                  resistant to stains, wear, impact, scratches, burns and
                  household chemicals. It consists of several melamine layers
                  that are pressed onto the HDF core board in a press, under
                  high pressure and temperature. The surface layer is finished
                  with a wood or tile texture/structure that is called
                  ‘registered-embossed’. This means that this surface structure
                  is perfectly in harmony with the design.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "50%", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #004994",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#004994",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    2
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  A design layer
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  This is the layer that defines the actual look of your floor.
                  The design layer is a very high resolution photo of real wood
                  or tiles that is reproduced with highly sophisticated printing
                  techniques and finished with melamine resin. The combination
                  of perfect design and surface structure makes the wood or tile
                  imitation look extremely real and natural..
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "50%", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #004994",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#004994",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    3
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  A moisture resistant glued HDF core board
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  This HDF core board is the heart of a Quick-Step Hybrid
                  Engineered floor. HDF means ‘High Density Fibre Board’. It
                  consists of refined wood fibres and high quality melamine
                  resin. It is a re-composed wood-fibre board which allows using
                  all part of the tree. The board is very homogenous in
                  composition and technical characteristics. It’s extremely
                  durable, stable and highly moisture resistant.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "50%", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #004994",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#004994",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    4
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  A balancing layer
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  This moisture-resistant backing layer guarantees a perfectly
                  balanced board that will not cup or bow when properly
                  installed and maintained.
                </Typography>
              </Box>
            </Box>
          </Box>
          <img
            src={Hybrid}
            alt={`UltraPage`}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
              width: "44%",
            }}
          />
        </Box>
      </Box>
      <Box sx={{ background: "#FAF8F5" }}>
        <Box sx={{ px: "129px", py: "60px" }}>
          <Typography
            sx={{
              pt: "16px",
              fontSize: "36px",
              lineHeight: "57.13px",
              color: "#AF9571",
              textAlign: "left !important",
              pb: "50px",
              fontWeight: "600",
            }}
          >
            Advantages
          </Typography>
          <Box sx={{ display: "flex", m: "0 -25px" }}>
            <Box sx={{ width: "calc(100%/3)", m: "0 25px" }}>
              <img
                src={Advantages01}
                alt={`Advantages01`}
                style={{ width: "300px" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                100% waterproof surface
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Say goodbye to moisture problems. Signature, Majestic,
                Impressive (Ultra), Impressive Patterns, Eligna and Classic
                Hybrid engineered floors have an innovative, water-repellent
                ‘HydroSeal’ coating even in the grooves, so that no water can
                penetrate. Cleaning has never before been so easy and so safe.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(100%/3)", m: "0 25px" }}>
              <img
                src={Advantages02}
                alt={`Advantages02`}
                style={{ width: "300px" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Years of carefree living
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Thanks to the Scratch Guard top coating, Quick- Step Hybrid
                engineered floors are up to ten times more resistant to
                scratches than most other Engineered floors. Scratch Guard gives
                your floor lasting protection against wear and tear from falling
                objects, furniture marks, high heels, etc. Moreover, your new
                Quick-Step laminate floor comes with a guarantee for up to 25
                years: your guarantee of years of carefree living.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(100%/3)", m: "0 25px" }}>
              <img
                src={Advantages03}
                alt={`Advantages03`}
                style={{ width: "300px" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Natural look
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Closely examine the floor surface and you will notice it
                immediately: Quick-Step Hybrid engineered floors are the most
                natural Hybrid engineered floors ever made. The authentic
                character of the joints is in perfect balance with the design of
                the floor. The colour, design and structure extend right into
                the joint and create the feeling of real wood.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: "129px", py: "60px" }}>
        <Typography
          sx={{
            pt: "16px",
            fontSize: "36px",
            lineHeight: "57.13px",
            color: "#AF9571",
            textAlign: "left !important",
            pb: "50px",
            fontWeight: "600",
          }}
        >
          Colours
        </Typography>
        <Box sx={{ display: "flex", margin: "-30px -75px", flexWrap: "wrap" }}>
          {data.map((r) => (
            <Box
              sx={{
                margin: "30px 75px",
                width: "calc(50% - 150px)",
                textAlign: "right",
              }}
            >
              <img
                src={r.imageUrlProduct}
                alt={`Ultra01`}
                style={{ width: "500px", cursor: "pointer" }}
                onClick={() => goTo("/contact-us", r.id)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ px: "129px", py: "30px" }}>
        <Typography
          sx={{
            fontSize: "36px",
            lineHeight: "57.13px",
            color: "#AF9571",
            textAlign: "left !important",
            pb: "50px",
            fontWeight: "600",
          }}
        >
          Advantages size
        </Typography>
        <img
          src={HybridSize}
          alt={`HybridSize`}
          style={{ width: "100%", maxWidth: "1633px" }}
        />
      </Box>
    </>
  );
};

export default HybridPage;
