import React from "react";
import Map1 from "./map1";
import Map2 from "./map2";

const Contact = () => {
  return (
    <>
      <div>
        <Map1 />
        <Map2 />
      </div>
    </>
  );
};

export default Contact;
