import React from "react";
import { Typography, Paper } from "@mui/material";
import Banner from "../../assets/banner.png";
import Carousel from "react-material-ui-carousel";
const images = [
  Banner,
  Banner,
  Banner,
  // เพิ่มรูปภาพตามต้องการ
];

function ImageSlider() {
  return (
    <Carousel>
      {images.map((image, index) => (
        <Paper key={index} sx={{ display: "flex" }}>
          {/*<div style={{ flex: 1 }}>*/}
          {/*  <h2>item.name</h2>*/}
          {/*  <p>item.description</p>*/}
          {/*</div>*/}
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Paper>
      ))}
    </Carousel>
  );
}

export default ImageSlider;
