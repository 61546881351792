import React from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import img1 from "../../assets/blog3img1.png";
import img2 from "../../assets/blog3img2.png";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import "../../scss/blog.scss";
const Blog5 = () => {
  return (
    <>
      <div style={{ backgroundColor: "#FFF8F0" }} className="mt-5">
        <Container maxWidth="lg">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                What We Can Offer
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "85%",
                }}
              />
            </div>
          </div>
          <div className="mt-5">
            <div
              style={{ position: "relative" }}
              className="m-auto pb-3 text-left"
            >
              <p>
                Providing one-stop service for innovative construction products,
                surface finishing material and solution services, O2E Supply has
                a professional team who can provide quality construction
                products and expert service solutions to meet customer’s
                satisfaction.
              </p>
            </div>
          </div>

          <div className="d-show1">
            <div
              className="mt-5"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
              }}
            >
              <div style={{ position: "relative" }} className="m-auto pb-3">
                <Card sx={{ maxWidth: 500 }}>
                  <CardMedia
                    component="iframe"
                    title="YouTube video"
                    src="https://www.youtube.com/embed/ZcnUcuO4q2k"
                    allowFullScreen
                    sx={{ width: 500, height: 300 }}
                  />
                </Card>
              </div>
              <div
                style={{ position: "relative" }}
                className="m-auto pb-3 text-right"
              >
                <div style={{ width: "fit-content", marginLeft: "auto" }}>
                  <p className={"text-topic"} style={{ margin: 10 }}>
                    Ordinary to Extraordinary
                  </p>
                  <Divider
                    style={{
                      borderColor: "#AF9571",
                      borderWidth: 2,
                      borderRadius: 3,
                      margin: "auto",
                      width: "65%",
                    }}
                  />
                </div>
                <p>
                  According to our slogan “Ordinary to Extraordinary”. we supply
                  a wide range of construction products from basic products to
                  advanced products. This is to ensure that our products are
                  variety and can be tailored to every customer’s need and
                  expectation.
                </p>
                <div className="mt-3">
                  <Button
                    variant="outlined"
                    sx={{
                      margin: "auto",
                      borderColor: "#AF9571",
                      color: "#AF9571",
                      width: 300,
                      height: 60,
                      fontSize: 18,
                      textTransform: "none",
                    }}
                    // onClick={handleSeeMoreClick}
                  >
                    <p>View Products</p>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="d-show2">
            <div className="mt-5">
              <div style={{ position: "relative" }} className="m-auto pb-3">
                <Card sx={{ maxWidth: 500 }}>
                  <CardMedia
                    component="iframe"
                    title="YouTube video"
                    src="https://www.youtube.com/embed/ZcnUcuO4q2k"
                    allowFullScreen
                    sx={{ width: 360, height: 300 }}
                  />
                </Card>
              </div>
              <div
                style={{ position: "relative" }}
                className="m-auto pb-3"
              >
                <div style={{ width: "fit-content", marginLeft: "auto" }}>
                  <p className={"text-topic"} style={{ margin: 10 }}>
                    Ordinary to Extraordinary
                  </p>
                  <Divider
                    style={{
                      borderColor: "#AF9571",
                      borderWidth: 2,
                      borderRadius: 3,
                      margin: "auto",
                      width: "65%",
                    }}
                  />
                </div>
                <p>
                  According to our slogan “Ordinary to Extraordinary”. we supply
                  a wide range of construction products from basic products to
                  advanced products. This is to ensure that our products are
                  variety and can be tailored to every customer’s need and
                  expectation.
                </p>
                <div className="mt-3">
                  <Button
                    variant="outlined"
                    sx={{
                      margin: "auto",
                      borderColor: "#AF9571",
                      color: "#AF9571",
                      width: 300,
                      height: 60,
                      fontSize: 18,
                      textTransform: "none",
                    }}
                    // onClick={handleSeeMoreClick}
                  >
                    <p>View Products</p>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Blog5;
