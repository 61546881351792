import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import * as PropTypes from "prop-types";
import { numberWithCommas } from "../../helpers/helper";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function Item(props) {
  return null;
}

Item.propTypes = { children: PropTypes.node };
export default function ProductCard({ image, title, detail, stock, price }) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <img src={image} width={170} height={170}/>
        </Typography>
        <Typography
          sx={{ mb: 1, textAlign: "left", mt: 2, fontSize: 12 }}
          color="text.secondary"
        >
          <div>{title}</div>
          <div>{detail}</div>
          {/*{detail?.map((item, index) => (*/}
          {/*  <div key={index}>*/}
          {/*    {Object.values(item).map((text, subIndex) => (*/}
          {/*      <div key={subIndex}>*/}
          {/*        {text} <br />*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*))}*/}
        </Typography>
        <Divider
          style={{
            borderRadius: 3,
          }}
        />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Typography
              sx={{ mb: 1, textAlign: "left", mt: 2, fontSize: 12 }}
              color="text.secondary"
            >
              In-stock:{stock}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ mb: 1, textAlign: "right", mt: 2, fontSize: 12 }}
              color="text.secondary"
            >
              ฿ {numberWithCommas(price)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
