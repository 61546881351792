import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";

const AdminHome = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Container maxWidth="xl" className="mb-2 mt-3">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            sx={{
              // margin: "auto",
              borderColor: "#AF9571",
              color: "#AF9571",
              width: 300,
              height: 60,
              fontSize: 18,
              textTransform: "none",
              marginRight: 10, // เพิ่ม margin ด้านขวา
            }}
            onClick={() => navigate("journalsandblogs")}
          >
            <p>Journals & Blogs</p>
          </Button>
          <Button
            variant="outlined"
            sx={{
              // margin: "auto",
              borderColor: "#AF9571",
              color: "#AF9571",
              width: 300,
              height: 60,
              fontSize: 18,
              textTransform: "none",
              marginLeft: 10, // เพิ่ม margin ด้านซ้าย
            }}
            onClick={() => navigate("journalsandblogs/seemore")}
          >
            <p>Journals & Blogs See more</p>
          </Button>
          <Button
            variant="outlined"
            sx={{
              // margin: "auto",
              borderColor: "#AF9571",
              color: "#AF9571",
              width: 300,
              height: 60,
              fontSize: 18,
              textTransform: "none",
              marginLeft: 10, // เพิ่ม margin ด้านซ้าย
            }}
            onClick={() => navigate("product")}
          >
            <p>Product</p>
          </Button>
          <Button
            variant="outlined"
            sx={{
              // margin: "auto",
              borderColor: "#AF9571",
              color: "#AF9571",
              width: 300,
              height: 60,
              fontSize: 18,
              textTransform: "none",
              marginLeft: 10, // เพิ่ม margin ด้านซ้าย
            }}
            onClick={() => navigate("contact")}
          >
            <p>Customer</p>
          </Button>
        </div>
      </Container>
    </>
  );
};

export default AdminHome;
