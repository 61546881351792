import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../scss/login.scss";
import apiLogin from "../../../api/login";
import http from "../../../api/http-common";
import Swal from "sweetalert2";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Login = () => {
  const navigate = useNavigate();
  // const history = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const onLogin = async () => {
    try {
      const response = await apiLogin.login({
        email: username,
        password: password,
      });
      if (response) {
        http.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        localStorage.setItem("access-token", response.data.token);
        localStorage.setItem("user-info", JSON.stringify(response.data.info));
        localStorage.setItem(
          "user-permissions",
          JSON.stringify(response.data.permissions),
        );
        navigate("/o2e-admin");
      }
    } catch (e) {
      alertSweet(
        "ไม่สำเร็จ",
        "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง",
        "error",
      );
    }
  };

  const alertSweet = (title, text, icon) => {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
    });
  };

  return (
    <div className="login">
      <Container>
        <div className="mt-5">
          <Card
            sx={{
              minWidth: 275,
              display: "inline-grid",
              padding: 5,
              textAlign: "center",
            }}
          >
            <h1>เข้าสู่ระบบ</h1>
            <TextField
              id="outlined-basic"
              label="E-mail"
              variant="outlined"
              type="email"
              onChange={(e) => setUsername(e.target.value)}
            />
            <br />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <Button
              variant="outlined"
              sx={{
                borderColor: "#AF9571",
                color: "#AF9571",
                width: "100%",
                height: 60,
                fontSize: 18,
                textTransform: "none",
                marginRight: 10, // เพิ่ม margin ด้านขวา
              }}
              onClick={() => onLogin()}
            >
              <p>เข้าสู่ระบบ</p>
            </Button>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
