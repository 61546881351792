import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import pimg1 from "../../assets/Portfolio1.png";
import Button from "@mui/material/Button";
import img1 from "../../assets/port1.png";
import img2 from "../../assets/port2.png";
import img3 from "../../assets/port3.png";
import img4 from "../../assets/port4.png";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
const Portfolio = () => {
  const navigate = useNavigate();

  return (
    <>
      <div style={{ width: 1000, margin: "auto" }}>
        <Card className="mt-3" sx={{ width: "fit-content" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={pimg1} />
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ backgroundColor: "#AF9571" }}
              style={{ paddingLeft: 8 }}
            >
              <div style={{ maxWidth: 500, marginTop: "15%" }}>
                <div style={{ width: 500 }}>
                  <p className="text-white">Baan Lae Suan Fair 2021</p>
                </div>
                <div style={{ width: 480 }}>
                  <p className="text-white" style={{ fontSize: 12 }}>
                    A popular home and garden decor fair is back, this time
                    aiming at Thais and foreigners struggling to navigate the
                    challenges of limited living spaces in Bangkok’s condos and
                    small homes.
                    <br />
                    <br />
                    At Baanlaesuan Midyear Fair 2021, the highlights are markets
                    selling goods for every lifestyle and demonstrations of what
                    an ideal home might look like in the confines of city lives.{" "}
                  </p>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    sx={{
                      margin: 10,
                      borderColor: "#FFF",
                      color: "#FFF",
                      width: 300,
                      height: 60,
                      fontSize: 13,
                      textTransform: "none",
                    }}
                    onClick={() => navigate("/portfolio/portseemore")}
                    disableRipple
                  >
                    <p>Read More</p>
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>

      <div
        className="mt-5"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
        }}
      >
        <div style={{ position: "relative" }} className="ml-auto pb-3">
          <Card sx={{ maxWidth: 400 }}>
            <CardMedia sx={{ height: 200 }} component={"img"} image={img1} />
            <CardContent sx={{ textAlign: "left" }}>
              <Typography
                gutterBottom
                component="div"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              >
                AMORNPIMON
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: 12 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                consectetur ...
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ position: "relative" }} className="mr-auto pb-3">
          <Card sx={{ maxWidth: 400 }}>
            <CardMedia sx={{ height: 200 }} component={"img"} image={img2} />
            <CardContent sx={{ textAlign: "left" }}>
              <Typography
                gutterBottom
                component="div"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              >
                AMORNPIMON
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: 12 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                consectetur ...
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
      <div
        className="mt-5"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
        }}
      >
        <div style={{ position: "relative" }} className="ml-auto pb-3">
          <Card sx={{ maxWidth: 400 }}>
            <CardMedia sx={{ height: 200 }} component={"img"} image={img3} />
            <CardContent sx={{ textAlign: "left" }}>
              <Typography
                gutterBottom
                component="div"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              >
                Baan Klang Muang Cla
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: 12 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                consectetur ...
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div style={{ position: "relative" }} className="mr-auto pb-3">
          <Card sx={{ maxWidth: 400 }}>
            <CardMedia sx={{ height: 200 }} component={"img"} image={img4} />
            <CardContent sx={{ textAlign: "left" }}>
              <Typography
                gutterBottom
                component="div"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              >
                Bluport Huahin Resor
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: 12 }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a
                consectetur ...
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};
export default Portfolio;
