import http from "./http-common";

const path = "/product";
const getData = () => http.get(`${path}`);
const getDataById = (id) => http.get(`${path}/${id}`);
const create = (data) => http.post(`${path}/create`, data);
const remove = (data) => http.delete(`${path}/${data}`);

export default {
  create,
  getData,
  remove,
  getDataById,
};
