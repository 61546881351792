import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

const pages = [
  "Home",
  "Portfolio",
  "Product",
  "Roomvo",
  "AR&VR",
  "Home Decorate",
  "Catalogue",
  "Contact us",
];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openSubNav, setOpenSubNav] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("home");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setActiveMenu(page);
    setAnchorElNav(null);
    if (page === "Home") {
      setOpenSubNav(false);
      navigate("/");
    }
    if (page === "Portfolio") {
      setOpenSubNav(false);
      navigate("/portfolio");
    }
    if (page === "Product") {
      onClickShowSubNav();
    }
    if (page === "Roomvo") {
      setOpenSubNav(false);
    }
    if (page === "AR&VR") {
      setOpenSubNav(false);
    }
    if (page === "Home Decorate") {
      setOpenSubNav(false);
    }
    if (page === "Catalogue") {
      setOpenSubNav(false);
      navigate("/catalogue");
    }
    if (page === "Contact us") {
      setOpenSubNav(false);
      navigate("/contact");
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onClickShowSubNav = () => {
    setOpenSubNav(!openSubNav);
  };

  const goTo = (path) => {
    setOpenSubNav(false);
    navigate(path);
  };

  return (
    <>
      {localStorage.getItem("access-token") === null ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ backgroundColor: "#494949" }}>
            <Toolbar variant="dense">
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                sx={{
                  fontSize: "15px", // กำหนดขนาดตัวหนังสือ
                  textTransform: "uppercase", // ทำให้เป็นพิมพ์ใหญ่ทั้งหมด
                  margin: "auto",
                }}
              >
                Ends today: Up to 30% off Quality products Installed by skilled
                technicians Impressive service Shop now
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      ) : null}
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <AppBar position="static" sx={{ backgroundColor: "#2D2D2F" }}>
          <Container maxWidth="xl">
            <Container maxWidth="xl">
              <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
                {/*<AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />*/}
                <Avatar
                  alt="Logo"
                  src={Logo}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    width: "auto",
                    borderRadius: "0%",
                    marginRight: 2,
                  }}
                />

                {localStorage.getItem("access-token") === null ? (
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      // onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      {pages.map((page) => (
                        <MenuItem
                          key={page}
                          onClick={() => handleCloseNavMenu(page)}
                        >
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : null}

                {/*<AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />*/}
                <Avatar
                  alt="Logo"
                  src={Logo}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    width: "auto",
                    borderRadius: "0%",
                    marginRight: 2,
                  }}
                />
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "none" },
                    flexGrow: 1,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                ></Typography>
                {localStorage.getItem("access-token") === null ? (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      justifyContent: "end",
                    }}
                  >
                    {pages.map((page) => (
                      <Button
                        key={page}
                        onClick={() => handleCloseNavMenu(page)}
                        sx={{ my: 2, color: "white", display: "block" }}
                        style={
                          activeMenu === page ? { color: "#AF9571" } : null
                        }
                      >
                        {page}
                      </Button>
                    ))}
                  </Box>
                ) : null}

                {localStorage.getItem("access-token") !== null ? (
                  <Box sx={{ flexGrow: 0, textAlign: "right" }}>
                    <Button
                      sx={{ my: 2, color: "white", display: "block" }}
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                      }}
                    >
                      LOGOUT
                    </Button>
                  </Box>
                ) : null}
              </Toolbar>
            </Container>
          </Container>
        </AppBar>
        <Box
          sx={{
            position: "absolute",
            zIndex: 9,
            width: "100%",
            overflow: "hidden",
            height: openSubNav ? "200px" : "0px",
            background: "#FFFFFF",
            boxShadow: "inset 0px 4px 10px rgba(0,0,0,0.15)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: "12px",
              px: "12px",
            }}
          >
            <Box sx={{ pr: "30px", pt: "6px" }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  lineHeight: "25.39px",
                  color: "#333333",
                  textAlign: "left !important",
                  pb: "7px",
                  fontWeight: "700",
                }}
              >
                Innovation Flooring
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "22.22px",
                  color: "#333333",
                  textAlign: "left !important",
                  pb: "7px",
                  cursor: "pointer",
                  fontWeight: "400",
                }}
                onClick={(e) => goTo("/ultra-engineered")}
              >
                Ultra Engineered
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "22.22px",
                  color: "#333333",
                  textAlign: "left !important",
                  pb: "7px",
                  cursor: "pointer",
                  fontWeight: "400",
                }}
                onClick={(e) => goTo("/hybrid-engineered")}
              >
                Hybrid Engineerd
              </Typography>
            </Box>
            <Box
              sx={{
                pt: "6px",
                display: "flex",
                borderLeft: "0.5px solid #AF9571",
              }}
            >
              <Box sx={{ px: "30px" }}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    lineHeight: "25.39px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "700",
                  }}
                >
                  Italy Luxury
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "600",
                  }}
                >
                  Foglie d'Oro
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    cursor: "pointer",
                    fontWeight: "400",
                  }}
                >
                  Special Panel wood floor
                </Typography>
              </Box>
              <Box sx={{ px: "30px" }}>
                <Typography
                  sx={{
                    pt: "32.39px",
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "600",
                  }}
                >
                  Foglie d'Oro
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "400",
                  }}
                >
                  Special pattern
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "400",
                  }}
                >
                  Planks
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22.22px",
                    color: "#333333",
                    textAlign: "left !important",
                    pb: "7px",
                    fontWeight: "400",
                  }}
                >
                  Herringbones and Chevrons
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default ResponsiveAppBar;
