import React from "react";
import { Grid } from "@mui/material";
import * as PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Logo from "../../assets/logo.png";
import Container from "@mui/material/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faSquareInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Divider } from "@mui/material";
import "../../scss/footer.scss";

function Item(props) {
  return null;
}

Item.propTypes = { children: PropTypes.node };

const Footer = () => {
  return (
    <div style={{ paddingTop: 300 }}>
      <div className="footer-container sticky-footer">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className="text-left">
              <img src={Logo} alt="Logo" className="logo" />
              <p className="text-white">
                The professional construction material
                <br />
                “Ordinary Towards Elegance”
              </p>
            </Grid>
            <Grid item xs={6} sm={2} className="text-left">
              {/* Adjust the number of columns based on your design */}
              <p className="text-white">Home</p>
              <p className="text-white">Portfolio</p>
              <p className="text-white">Product</p>
              <p className="text-white">Roomvo</p>
            </Grid>
            <Grid item xs={6} sm={2} className="text-left">
              {/* Adjust the number of columns based on your design */}
              <p className="text-white">AR&VR</p>
              <p className="text-white">Catalogue</p>
              <p className="text-white">Contact us</p>
              <p className="text-white">Jourals & Blogs</p>
            </Grid>
            <Grid item xs={6} sm={2}>
              {/* Adjust the number of columns based on your design */}
              <p className="text-white">Connect with us</p>
              <FontAwesomeIcon
                icon={faSquareFacebook}
                color={"#af9571"}
                size={"2xl"}
                style={{ marginRight: 20 }}
                className="set-cursor"
              />
              <FontAwesomeIcon
                icon={faSquareInstagram}
                color={"#af9571"}
                size={"2xl"}
                style={{ marginRight: 20 }}
                className="set-cursor"
              />
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <p className="text-white">Contact us</p>
              <p className="text-white" style={{ fontSize: 12 }}>
                345/7, Headquarters,
                <br />
                Ladprao94 (Panjamitr), Plubpla,
                <br />
                Wangthonglang, Bangkok, 10310
                <br />
                <br />
                Call: 02-194-4769-70
                <br />
                Mobile: 08-1841-4677
                <br />
                Email: info@o2esupply.co.th
              </p>
            </Grid>
          </Grid>
          <div>
            <Divider
              sx={{
                backgroundColor: "#D0D0D0",
                width: "100%",
                marginBottom: 1,
              }}
            />
            <p className="text-white" style={{ margin: 0, fontSize: 12 }}>
              Copyright © 2021 O2E supply company limited
            </p>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
