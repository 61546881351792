import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Divider, Grid } from "@mui/material";
import img from "../../assets/blog2img1.png";
import img1 from "../../assets/blog4img1.png";
import img2 from "../../assets/blog4img2.png";
import img3 from "../../assets/blog4img3.png";
import img4 from "../../assets/blog4img4.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import apiContent from "../../api/content";
const Blog4 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await apiContent.getData();
    const slicedData = res.data.elements.slice(0, 4);
    setData(slicedData);
  };

  return (
    <>
      <div className="mt-5">
        <Container maxWidth="xl">
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="mb-3"
          >
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                Journals & Blogs
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "85%",
                }}
              />
            </div>
          </div>
          <div>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid container spacing={2}>
                {data.map((r) => (
                  <Grid item xs={3}>
                    <img
                      src={r.imageUrlContent}
                      alt="Image 1"
                      style={{ width: "80%", marginBottom: "0px" }}
                    />
                    <p
                      style={{
                        fontSize: 10,
                        margin: 0,
                        marginLeft: "10%",
                        width: "80%",
                        overflowWrap: "break-word",
                      }}
                      className="text-left"
                    >
                      <h4>{r.title}</h4>
                      {r.description}
                    </p>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </div>
          <div className="mt-3">
            <Button
              variant="outlined"
              sx={{
                margin: "auto",
                borderColor: "#AF9571",
                color: "#AF9571",
                width: 300,
                height: 60,
                fontSize: 18,
                textTransform: "none",
              }}
              onClick={() => navigate("/seemoreBlog4")}
            >
              <p>See All Journals & Blogs</p>
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Blog4;
