import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Ultra from "../../assets/ultra.png";
import UltraSize from "../../assets/ultraSize.png";
import ArrowUp from "../../assets/icon/arrowUp.png";
import ArrowDown from "../../assets/icon/arrowDown.png";
import UltraAdvantages03 from "../../assets/ultraAdvantages03.png";
import UltraAdvantages02 from "../../assets/ultraAdvantages02.png";
import UltraAdvantages01 from "../../assets/ultraAdvantages01.png";
import Ultra01 from "../../assets/ultra01.png";
import Ultra02 from "../../assets/ultra02.png";
import Ultra03 from "../../assets/ultra03.png";
import Ultra04 from "../../assets/ultra04.png";
import { useNavigate } from "react-router-dom";
import apiProduct from "../../api/product";

const UltraPage = () => {
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await apiProduct.getData();
    setData(res.data.elements);
  };

  const goTo = (path, id) => {
    navigate(path, {
      state: {
        id: id,
      },
    });
  };
  const handleSeeMoreClick = () => {
    setHidden(!hidden);
  };

  return (
    <>
      <Box sx={{ pl: "129px", pt: "60px" }}>
        <Box sx={{ pb: "20px" }}>
          <Typography
            sx={{
              fontSize: "36px",
              lineHeight: "57.13px",
              color: "#333333",
              // textAlign: "left !important",
              pb: "7px",
              fontWeight: "600",
            }}
          >
            ULTRA ENGINEERED
          </Typography>
          <Box
            sx={{
              background: "#AF9571",
              height: "3px",
              width: "234px",
              m: "0 auto",
              borderRadius: "2px",
            }}
          ></Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ width: "72%", display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ width: "calc(100%/3)", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #FAA60A",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#FAA60A",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    1
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  Titanium: the invisible 8-layer varnish
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  We can call it Titanium! Extremely strong due to eight layers
                  of especially developed “bullet” proof lacquer. Your floor
                  will be resistant to daily wear and tear as well as scratches,
                  the pores are moist and dirt resistant. A brushed finish is
                  available allowing you to feel the natural grains and patterns
                  of the wood. This extremely strong and matt lacquer is nearly
                  invisible.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "calc(100%/3)", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #FAA60A",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#FAA60A",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    2
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  Real Wood
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  On the HDF board we press a wood wear layer according to the
                  DSI system (Double sided impregnation). During this process
                  the adhesive gets pressed into the wood. While lacquering the
                  top layer, the wood absorbs the varnish and makes a fusion
                  with the adhesive. The result is a Titanium wooden top layer.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "calc(100%/3)" }}></Box>
            <Box sx={{ width: "calc(100%/3)", pb: "60px" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #FAA60A",
                  px: "30px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#FAA60A",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    3
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600",
                  }}
                >
                  Strong high-density board
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  The thinner the wooden top layer, the more resistant the floor
                  is to impact. The HDF just below the wear layer is even
                  stronger than natural wood. This allows us to make sure the
                  floor is more resistant to heavy objects or stiletto heels.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "calc(100%/3)" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #FAA60A",
                  px: "30px",
                  mb: "60px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#FAA60A",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    4
                  </Typography>
                </Box>
                {/* <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600"
                  }}
                >
                  A balancing layer
                </Typography> */}
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  Wood backing for dimensional stability.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: "calc(100%/3)" }}>
              <Box
                sx={{
                  height: "max-content",
                  borderLeft: "1px solid #FAA60A",
                  px: "30px",
                  mb: "60px",
                }}
              >
                <Box
                  sx={{
                    height: "34px",
                    width: "34px",
                    background: "#FAA60A",
                    borderRadius: "17px",
                    mb: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "34px",
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    5
                  </Typography>
                </Box>
                {/* <Typography
                  sx={{
                    fontSize: "24px",
                    lineHeight: "38.09px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "600"
                  }}
                >
                  A balancing layer
                </Typography> */}
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "31.74px",
                    color: "#333333",
                    textAlign: "left !important",
                    fontWeight: "400",
                  }}
                >
                  With Deluxe+ and Royal+ a 2 mm layer of cork provides a softer
                  footfall sound.
                </Typography>
              </Box>
            </Box>
          </Box>
          <img
            src={Ultra}
            alt={`UltraPage`}
            style={{ position: "absolute", top: "0", right: "0", width: "52%" }}
          />
        </Box>
      </Box>
      <Box sx={{ background: "#FAF8F5" }}>
        <Box sx={{ px: "129px", pt: "60px", pb: "30px" }}>
          <Typography
            sx={{
              pt: "16px",
              fontSize: "36px",
              lineHeight: "57.13px",
              color: "#AF9571",
              textAlign: "left !important",
              pb: "50px",
              fontWeight: "600",
            }}
          >
            Advantages
          </Typography>
          <Box sx={{ display: "flex", m: "-15px -25px", flexWrap: "wrap" }}>
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages01}
                alt={`UltraAdvantages01`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                A sustainable choice
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Wood is precious. We use it sparingly: we only use a thin layer
                of wood for the top finish. We source our trees from sustainable
                managed forests and every board is 100% recyclable,
                participating as such in the circular economy. We source energy
                from solar and wind production. The sawdust and wood waste from
                our production facility is used to heat the floor space. The
                future is a CO2 neutral world.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages02}
                alt={`UltraAdvantages02`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Real wood
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Feel real wood. Each Parky floor is finished with a top layer of
                authentic wood species. Just like a solid wood floor, no two
                boards are the same. Oak, teak or other species give that
                beautiful natural look you are aiming for. The warmth of wood
                can never be found in fake.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages03}
                alt={`UltraAdvantages03`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                High-strength and scratch resistant
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Scratch resistant Parky floors are ultra-high-strength. Our new
                Titanium finish has eight robust layers of lacquer. This makes
                your floor almost scratch-resistant and ready for intensive use.
                High-strength Our Titanium finished wood in combination with a
                core out of high density fibre board (HDF), makes your floor
                stronger and more dimensionally stable than any traditional
                solid or engineered wooden floor. Use Parky with peace of mind
                in the toughest places of your house such as your kitchen or
                high traffic areas in commercial use such as hotel lobbies,
                offices, retail stores…
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              m: "-15px -25px",
              flexWrap: "wrap",
              overflow: "hidden",
              height: hidden ? "0px" : "auto",
            }}
          >
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages01}
                alt={`UltraAdvantages01`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Waterproof surface
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                The Parky board has a waterproof surface. The combination with
                the Aqua Sealing Complete system, which protects the bevels,
                gives you a 12 hour waterproof floor. This makes your floor
                bullet proof for daily spills and drops.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages02}
                alt={`UltraAdvantages02`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Perfect match with your radiant heating
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                Different kinds of radiant heating systems can be used with
                Parky. All technical details can be found on our website.
              </Typography>
            </Box>
            <Box sx={{ width: "calc(33.333% - 50px)", m: "15px 25px" }}>
              <img
                src={UltraAdvantages03}
                alt={`UltraAdvantages03`}
                style={{ width: "100%" }}
              />
              <Typography
                sx={{
                  pt: "16px",
                  fontSize: "24px",
                  lineHeight: "38.09px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "600",
                }}
              >
                Floor for healthier indoor air quality
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "31.74px",
                  color: "#333333",
                  textAlign: "left !important",
                  fontWeight: "400",
                }}
              >
                The Parky floor is only finished with water based stains,
                adhesives without volatile organic compounds and solvent free
                paints. It is scientifically proven: indoor air quality improves
                with a Parky floor. All floors have a A+ quality label.
              </Typography>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              margin: 10,
              borderColor: "#AF9571",
              color: "#333",
              width: 220,
              height: 50,
              fontSize: 24,
              lineHeight: "50px",
              m: "40px 0 0",
              borderWidth: "2px",
              borderRadius: "10px",
            }}
            onClick={handleSeeMoreClick}
          >
            <p>
              {hidden ? "See More" : "Hide"}{" "}
              <span>
                <img
                  src={hidden ? ArrowDown : ArrowUp}
                  alt={hidden ? "ArrowDown" : "ArrowUp"}
                  // style={{ width: "100%", maxWidth: "1633px" }}
                />
              </span>
            </p>
          </Button>
        </Box>
      </Box>
      <Box sx={{ px: "129px", py: "60px" }}>
        <Typography
          sx={{
            pt: "16px",
            fontSize: "36px",
            lineHeight: "57.13px",
            color: "#AF9571",
            textAlign: "left !important",
            pb: "50px",
            fontWeight: "600",
          }}
        >
          Colours
        </Typography>
        <Box sx={{ display: "flex", margin: "-30px -75px", flexWrap: "wrap" }}>
          {data.map((r) => (
            <Box
              sx={{
                margin: "30px 75px",
                width: "calc(50% - 150px)",
                textAlign: "right",
              }}
            >
              <img
                src={r.imageUrlProduct}
                alt={`Ultra01`}
                style={{ width: "500px", cursor: "pointer" }}
                onClick={() => goTo("/contact-us", r.id)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={{ px: "129px", py: "30px" }}>
        <Typography
          sx={{
            fontSize: "36px",
            lineHeight: "57.13px",
            color: "#AF9571",
            textAlign: "left !important",
            pb: "50px",
            fontWeight: "600",
          }}
        >
          Advantages size
        </Typography>
        <img
          src={UltraSize}
          alt={`UltraSize`}
          style={{ width: "100%", maxWidth: "1633px" }}
        />
      </Box>
    </>
  );
};

export default UltraPage;
