import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import img1 from "../../assets/port1.png";
import img2 from "../../assets/port2.png";
import img3 from "../../assets/port3.png";
import img4 from "../../assets/port4.png";

const images = [
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
];

const Gallery = () => {
  return (
    <>
      <div style={{ maxWidth: 1000, margin: "auto" }}>
        <ImageGallery items={images} />
      </div>
    </>
  );
};

export default Gallery;
