import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import apiContact from "../../../api/contact";

const ContactPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);

  const fetchData = async () => {
    const res = await apiContact.getData();
    const dataWithIndex = res.data.elements.map((element, index) => ({
      ...element,
      order: index + 1, // เพิ่มลำดับเข้าไปในแต่ละองค์ประกอบของอาร์เรย์
    }));
    setData(dataWithIndex);
  };

  const columns = [
    { field: "order", headerName: "ลำดับ", flex: 1 },
    { field: "email", headerName: "อีเมล", flex: 1 },
    { field: "name", headerName: "ชื่อ", flex: 1 },
    { field: "phone", headerName: "เบอร์โทร", flex: 1 },
  ];

  const getRowHeight = (params) => {
    return 300;
  };

  return (
    <Container maxWidth="xl" className="mb-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", padding: "20px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#AF9571",
              color: "#AF9571",
              height: 40,
              fontSize: 18,
              textTransform: "none",
            }}
            onClick={() => navigate("/o2e-admin")}
          >
            <p>ย้อนกลับ</p>
          </Button>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          // checkboxSelection
          getRowHeight={getRowHeight}
        />
      </div>
    </Container>
  );
};

export default ContactPage;
