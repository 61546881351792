import http from "./http-common";

const path = "/blog";
const getFileUrl = (data) => http.post(`/file/create`, data);
const getData = () => http.get(`${path}`);
const getDataById = (id) => http.get(`${path}/${id}`);
const create = (data) => http.post(`${path}/create`, data);
const update = (data) => http.put(`${path}/update`, data);
const remove = (data) => http.delete(`${path}/${data}`);

export default {
  create,
  update,
  getData,
  remove,
  getDataById,
  getFileUrl,
};
