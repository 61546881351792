import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Frame1 from "../../assets/frame1.png";
import Frame2 from "../../assets/frame2.png";
import Frame3 from "../../assets/frame3.png";
import Frame4 from "../../assets/frame4.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import apiProduct from "../../api/product";
import apiContact from "../../api/contact";
import Swal from "sweetalert2";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#AF9571",
  "&:hover": {
    backgroundColor: "#bda27b",
  },
}));

const HybridPage = () => {
  const location = useLocation();
  const state = location.state;
  const [data, setData] = useState(null);
  const [imageShow, setImageShow] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchData();
  }, [state]);
  const fetchData = async () => {
    const res = await apiProduct.getDataById(state?.id);
    setData(res.data);
    setImageShow(res?.data?.imageUrlProduct);
  };

  const onSubmit = async () => {
    const res = await apiContact.create({
      name: firstName,
      phone: phoneNumber,
      email: email,
    });
    if (res.status === 201) {
      setEmail("");
      setFirstName("");
      setPhoneNumber("");
      await Swal.fire({
        title: "สำเร็จ!",
        text: "เรียบร้อยแล้ว",
        icon: "success",
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          m: "0 auto",
          pt: "100px",
          maxWidth: "1152px",
          display: "flex",
          px: "50px",
        }}
      >
        <Box sx={{ width: "58.7%", pr: "20px" }}>
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "38.09px",
              color: "#333333",
              textAlign: "left !important",
              fontWeight: "400",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "28.57px",
              color: "#333333",
              textAlign: "left !important",
              pb: "49px",
              fontWeight: "400",
            }}
          >
            To submit a request, please fill in your information.
          </Typography>
          <Box sx={{ display: "flex", height: "243px", mb: "49px" }}>
            <Box
              sx={{
                width: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <img src={Frame1} alt={`Frame1`} style={{ display: "block" }} />
              </Box>
              <Box>
                <img src={Frame2} alt={`Frame2`} style={{ display: "block" }} />
              </Box>
              <Box>
                <img src={Frame3} alt={`Frame3`} style={{ display: "block" }} />
              </Box>
              <Box>
                <img src={Frame4} alt={`Frame4`} style={{ display: "block" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "calc(100% - 100px)",
                px: "20px",
              }}
            >
              <img
                src={imageShow}
                alt={`Image108`}
                style={{
                  display: "block",
                  height: "100%",
                  maxWidth: "calc(100% - 100px)",
                }}
              />
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "38.09px",
              color: "#333333",
              textAlign: "left !important",
              fontWeight: "400",
            }}
          >
            Colours
          </Typography>
          <Box sx={{ px: "30px", pb: "30px" }}>
            <Box
              sx={{
                m: "-10px -10px",
                display: "flex",
                flexWrap: "wrap",
                width: "510px",
              }}
            >
              {data?.colorsDTOList?.map((r) => {
                return (
                  <Box
                    sx={{ m: "10px 10px", cursor: "pointer" }}
                    onClick={() => setImageShow(r.imageUrlColors)}
                  >
                    <img
                      src={r?.imageUrlColors}
                      style={{
                        display: "block",
                        borderRadius: "20px",
                        height: "40px",
                        width: "40px",
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "188px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              Vendor item on.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              {data?.vendorItemNo}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "188px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              Size (cm)
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              {data?.size}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "188px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              Price
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "28.57px",
                color: "#333333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              ฿ {data?.price}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ borderLeft: "1px solid #EBEBEB", pl: "44px", width: "41.3%" }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "38.09px",
              color: "#333333",
              textAlign: "left !important",
              fontWeight: "500",
            }}
          >
            Planks floor Ca’ Da Ponte
          </Typography>
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "38.09px",
              color: "#333333",
              textAlign: "left !important",
              pb: "25px",
              fontWeight: "500",
            }}
          >
            Planks floor in American Walnut
          </Typography>
          <Box sx={{ border: "1px solid #DDDDDD", p: "10px 12px" }}>
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "28.57px",
                color: "#000",
                textAlign: "left !important",
                pb: "3px",
                fontWeight: "400",
              }}
            >
              Detail
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22.22px",
                color: "#333",
                textAlign: "left !important",
                fontWeight: "400",
              }}
            >
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum
            </Typography>
          </Box>
          <TextField
            fullWidth
            margin="normal"
            name="title"
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
            variant="standard"
            label="First Name"
            sx={{
              ".MuiOutlinedInput-root": {
                color: "#D0D0D0",
                borderRadius: "10px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D0D0D0",
              },
              ".MuiInputLabel-formControl": {
                color: "#D0D0D0",
                paddingLeft: "15px",
              },
            }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  color: "#D0D0D0",
                  fontWeight: "300",
                  opacity: "1",
                },
                padding: "8.5px 15px",
              },
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            name="title"
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="text"
            value={phoneNumber}
            variant="standard"
            label={"Phone Number"}
            sx={{
              ".MuiOutlinedInput-root": {
                color: "#D0D0D0",
                borderRadius: "10px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D0D0D0",
              },
              ".MuiInputLabel-formControl": {
                color: "#D0D0D0",
                paddingLeft: "15px",
              },
            }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  color: "#D0D0D0",
                  fontWeight: "300",
                  opacity: "1",
                },
                padding: "8.5px 15px",
              },
            }}
          />
          <TextField
            fullWidth
            margin="normal"
            name="title"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            value={email}
            variant="standard"
            label={"Email (Primary contact)"}
            sx={{
              ".MuiOutlinedInput-root": {
                color: "#D0D0D0",
                borderRadius: "10px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D0D0D0",
              },
              ".MuiInputLabel-formControl": {
                color: "#D0D0D0",
                paddingLeft: "15px",
              },
            }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  color: "#D0D0D0",
                  fontWeight: "300",
                  opacity: "1",
                },
                padding: "8.5px 15px",
              },
            }}
          />
          <Box sx={{ pt: "20px" }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#AF9571",
                color: "#AF9571",
                width: "100%",
                height: 65,
                fontSize: 18,
                fontWeight: "400",
                lineHeight: "65px",
                borderWidth: "1px",
                borderRadius: "10px",
              }}
            >
              Add LINE O2E Supply
            </Button>
          </Box>
          <Box sx={{ pt: "20px" }}>
            <ColorButton
              variant="contained"
              sx={{
                background: "#AF9571",
                borderColor: "#AF9571",
                color: "#fff",
                width: "100%",
                height: 65,
                fontSize: 20,
                fontWeight: "400",
                lineHeight: "65px",
                borderWidth: "1px",
                borderRadius: "10px",
              }}
              onClick={() => onSubmit()}
            >
              Submit a request
            </ColorButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HybridPage;
