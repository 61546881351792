import React from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import img from "../../assets/blog2img1.png";
import Card from "@mui/material/Card";
const Blog2 = () => {
  return (
    <>
      <div className="mt-5">
        <Container maxWidth="xl">
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="mb-3"
          >
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                Welcome To O2E’s Showroom
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "85%",
                }}
              />
            </div>
          </div>
          <div className="m-auto" style={{ width: "70%" }}>
            Providing one-stop service for innovative construction products,
            surface finishing material and solution services, O2E Supply has a
            professional team who can provide quality construction products and
            expert service solutions to meet customer’s satisfaction.
          </div>
          <div className="mt-3">
            <Card sx={{ maxWidth: 500 }} className="m-auto">
              <CardMedia component={"img"} image={img} />
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Blog2;
