import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import ProductCard from "../../components/card";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Blog1 from "./blog1";
import Blog2 from "./blog2";
import ImageSlider from "../../components/imageSlider";
import Blog3 from "./blog3";
import Blog4 from "./blog4";
import Blog5 from "./blog5";
import apiProduct from "../../api/product";

const productsPerPage = 4;

const Home = () => {
  const [visibleProducts, setVisibleProducts] = useState(productsPerPage);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
    if (localStorage.getItem("access-token") !== null) {
      localStorage.clear();
    }
  }, [localStorage.getItem("access-token")]);

  const fetchData = async () => {
    const res = await apiProduct.getData();
    setData(res.data.elements);
  };
  const handleSeeMoreClick = () => {
    setVisibleProducts(
      (prevVisibleProducts) => prevVisibleProducts + productsPerPage,
    );
  };

  return (
    <div>
      <ImageSlider />
      <Container maxWidth="xl" className="mb-2">
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mb-4"
        >
          <div style={{ width: "fit-content" }}>
            <h1 style={{ marginBottom: 0 }}>New Arrival</h1>
            <Divider
              style={{
                borderColor: "#AF9571",
                borderWidth: 2,
                borderRadius: 3,
                width: 250,
              }}
            />
          </div>
        </div>

        <div>
          <div className="product-grid">
            {data?.slice(0, visibleProducts).map((product, index) => (
              <ProductCard
                key={index}
                image={product?.imageUrlProduct}
                stock={product?.stock}
                title={product?.title}
                detail={product?.detail}
                price={product?.price}
              />
            ))}
          </div>
          {visibleProducts < data?.length && (
            <div className="mt-1">
              <Button
                variant="outlined"
                sx={{
                  margin: "auto",
                  borderColor: "#AF9571",
                  color: "#AF9571",
                  width: 300,
                  height: 60,
                  fontSize: 18,
                  textTransform: "none",
                }}
                onClick={handleSeeMoreClick}
              >
                <p>See more</p>
              </Button>
            </div>
          )}
        </div>
      </Container>
      <Blog1 />
      <Blog2 />
      <Blog3 />
      <Blog4 />
      <Blog5 />
    </div>
  );
};

export default Home;
