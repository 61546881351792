import React from "react";
import Card from "@mui/material/Card";
import { Divider, Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import pimg1 from "../../assets/Portfolio1.png";
import Button from "@mui/material/Button";
import Gallery from "./gallery";
import Container from "@mui/material/Container";
import img1 from "../../assets/port1.png";
import img2 from "../../assets/port2.png";
import img3 from "../../assets/port3.png";
import { useNavigate } from "react-router-dom";

const PortSeeMore = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div style={{ width: 1000, margin: "auto" }}>
          <Card className="mt-3" sx={{ width: "fit-content" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card sx={{ maxWidth: 500 }}>
                  <CardMedia component={"img"} image={pimg1} />
                </Card>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 8 }}>
                <div style={{ maxWidth: 500, marginTop: "15%" }}>
                  <div
                    style={{ width: 500, marginLeft: 20 }}
                    className="text-left"
                  >
                    <p className="" style={{ marginBottom: 2 }}>
                      The Address: Siam-Ratchathewi
                    </p>
                    <Divider
                      style={{
                        borderColor: "#AF9571",
                        borderWidth: 2,
                        borderRadius: 3,
                        width: "45%",
                      }}
                    />
                  </div>
                  <div
                    style={{ width: 480, marginLeft: 20 }}
                    className="text-left"
                  >
                    <p className="" style={{ fontSize: 12 }}>
                      Classic palace architecture fused with the beauty of
                      contemporary Thai identity. Detailed decoration adorned
                      with “Peacock” symbolizes luxuary, elegance, and timeless
                      design.
                      <br />
                      <br />
                      Classic French palace architecture serves as an
                      inspiration for this property's master plan and
                      traditional Thai decorative arts provide inspirations for
                      the five lounges that stand as elegant embodiments of
                      prestige-luxe living.
                    </p>
                  </div>
                  <div
                    style={{ width: 480, marginLeft: 20 }}
                    className="text-left"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        marginTop: 10,
                        borderColor: "#AF9571",
                        color: "#AF9571",
                        width: 300,
                        height: 60,
                        fontSize: 13,
                        textTransform: "none",
                      }}
                      onClick={() => navigate("/contact")}
                      disableRipple
                    >
                      <p>Want to create a project with us ?</p>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>

        <div style={{ backgroundColor: "#FFF8F0" }} className="mt-5">
          <Gallery />
        </div>

        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mb-4"
        >
          <div style={{ width: "fit-content" }}>
            <h2 style={{ marginBottom: 0 }}>See other projects</h2>
            <Divider
              className="m-auto"
              style={{
                borderColor: "#AF9571",
                borderWidth: 2,
                borderRadius: 3,
                width: 100,
              }}
            />
          </div>
        </div>
        <Container maxWidth="xl" className="mb-2">
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <img
                  src={img1}
                  alt="Image 1"
                  style={{ width: "80%", marginBottom: "0px" }}
                />
                <p
                  style={{ fontSize: 10, margin: 0, marginLeft: "10%" }}
                  className="text-left"
                >
                  Amornpimon
                </p>
              </Grid>

              <Grid item xs={4}>
                <img
                  src={img2}
                  alt="Image 2"
                  style={{ width: "80%", marginBottom: "0px" }}
                />
                <p
                  style={{ fontSize: 10, margin: 0, marginLeft: "10%" }}
                  className="text-left"
                >
                  Amornpimon
                </p>
              </Grid>

              <Grid item xs={4}>
                <img
                  src={img3}
                  alt="Image 3"
                  style={{ width: "80%", marginBottom: "0px" }}
                />
                <p
                  style={{ fontSize: 10, margin: 0, marginLeft: "10%" }}
                  className="text-left"
                >
                  Amornpimon
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default PortSeeMore;
