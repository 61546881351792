import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { Divider, Grid } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import pimg1 from "../../assets/blog4img5.png";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import img1 from "../../assets/blog4img6.png";
import img2 from "../../assets/blog4img7.png";
import img3 from "../../assets/blog4img8.png";
import img4 from "../../assets/blog4img9.png";
import img5 from "../../assets/blog4img10.png";
import img6 from "../../assets/blog4img11.png";
import { useNavigate } from "react-router-dom";
import "../../scss/blog.scss";
import apiContent from "../../api/content";
const SeemoreBlog4 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await apiContent.getData();
    setData(res.data.elements);
  };

  return (
    <>
      <div>
        <Container maxWidth="lg" className="mb-2">
          <div style={{ margin: "auto" }}>
            <Card className="mt-3 d-show1" sx={{ width: "fit-content" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Card sx={{ maxWidth: 800 }}>
                    <CardMedia component={"img"} image={pimg1} />
                  </Card>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: 8, backgroundColor: "#AF9571" }}
                >
                  <div
                    style={{
                      maxWidth: 500,
                      marginTop: "15%",
                      marginBottom: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div style={{ width: 500, marginLeft: 20 }} className="">
                      <p className="text-white" style={{ marginBottom: 2 }}>
                        Baan Lae Suan Fair 2021
                      </p>
                    </div>
                    <div style={{ width: 480, marginLeft: 20 }} className="">
                      <p className="text-white" style={{ fontSize: 12 }}>
                        A popular home and garden decor fair is back, this time
                        aiming at Thais and foreigners struggling to navigate
                        the challenges of limited living spaces in Bangkok’s
                        condos and small homes.
                        <br />
                        <br />
                        At Baanlaesuan Midyear Fair 2021, the highlights are
                        markets selling goods for every lifestyle and
                        demonstrations of what an ideal home might look like in
                        the confines of city lives.
                      </p>
                    </div>
                    <div
                      style={{ width: 480, marginLeft: "20%" }}
                      className="text-left"
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          marginTop: 10,
                          borderColor: "#FFF",
                          color: "#FFF",
                          width: 300,
                          height: 60,
                          fontSize: 13,
                          textTransform: "none",
                        }}
                        onClick={() => navigate("/seemore2Blog4")}
                        disableRipple
                      >
                        <p>Read More</p>
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>

            <Card className="mt-3 d-show2" sx={{ width: "100%" }}>
              <CardMedia component={"img"} image={pimg1} />

              <div
                style={{
                  paddingBottom: 20,
                  backgroundColor: "#AF9571",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    // maxWidth: "90%",
                    // marginTop: "15%",
                    marginBottom: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ marginLeft: 10 }} className="">
                    <p className="text-white" style={{ marginBottom: 2 }}>
                      Baan Lae Suan Fair 2021
                    </p>
                  </div>
                  <div style={{ marginLeft: 10 }} className="">
                    <p className="text-white" style={{ fontSize: 12 }}>
                      A popular home and garden decor fair is back, this time
                      aiming at Thais and foreigners struggling to navigate the
                      challenges of limited living spaces in Bangkok’s condos
                      and small homes.
                      <br />
                      <br />
                      At Baanlaesuan Midyear Fair 2021, the highlights are
                      markets selling goods for every lifestyle and
                      demonstrations of what an ideal home might look like in
                      the confines of city lives.
                    </p>
                  </div>
                  <div className="">
                    <Button
                      variant="outlined"
                      sx={{
                        marginTop: 5,
                        borderColor: "#FFF",
                        color: "#FFF",
                        width: 300,
                        height: 60,
                        fontSize: 13,
                        textTransform: "none",
                      }}
                      style={{ marginLeft: "auto" }}
                      onClick={() => navigate("/seemore2Blog4")}
                      disableRipple
                    >
                      <p>Read More</p>
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <Grid container spacing={2} style={{ marginTop: "5rem" }}>
            <Grid container spacing={2}>
              {data.map((r) => (
                <Grid item xs={4}>
                  <img
                    src={r.imageUrlContent}
                    alt="Image 1"
                    style={{ width: "80%", marginBottom: "0px" }}
                  />
                    <p
                      style={{
                        fontSize: 15,
                        margin: 0,
                        marginLeft: "10%",
                        overflowWrap: "break-word",
                      }}
                      className="text-left"
                    >
                      <h4>{r.title}</h4>
                      {r.description}
                    </p>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default SeemoreBlog4;
