import React from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import img1 from "../../assets/map1.png";
import icon2 from "../../assets/icon/icon2.png";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
const Map2 = () => {
  return (
    <div style={{ backgroundColor: "#FFF8F0" }}>
      <div style={{ width: 800 }} className="m-auto">
        <div
          className="mt-5"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          }}
        >
          <div
            onClick={() => {
              window.open("https://maps.app.goo.gl/dYBn8vTPzDJZ8teHA");
            }}
            style={{ cursor: "pointer" }}
          >
            <CardMedia sx={{ height: 300 }} component={"img"} image={img1} />
          </div>
          <div className="m-auto">
            <div style={{ display: "flex" }}>
              <Avatar alt="Remy Sharp" src={icon2} />
              <p
                style={{
                  marginTop: 5,
                  marginLeft: 10,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                Headquarters
              </p>
            </div>
            <p className="text-left">
              345/7, Headquarters, Ladprao94 (Panjamitr), <br />
              Plubpla, <br /> Wangthonglang, Bangkok, 10310
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map2;
