import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import apiContent from "../../../api/content";
import http from "../../../api/http-common";
import Swal from "sweetalert2";
const JournalsAndBlogsAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [imageURL, setImageURL] = useState(null);
  const [multiImageURL, setMultiImageURL] = useState([]);
  const [oldImageURL, setOldImageURL] = useState(null);
  const [oldMultiImageURL, setOldMultiImageURL] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const state = location.state;
  useEffect(() => {
    if (state?.type === "edit") {
      apiContent.getDataById(state?.id).then((r) => {
        setOldImageURL(r.data.imageUrlContent);
        setDescription(r.data.description);
        setTitle(r.data.title);
      });
    }
  }, [state?.id]);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setImageURL({ url: imageURL, file: file });
  };

  const onCreate = async () => {
    const data = new FormData();
    if (state?.type === "edit") {
      data.append("id", state?.id);
    }
    data.append("title", title);
    data.append("description", description);

    if (oldImageURL) {
    } else {
      data.append("fileContent", imageURL.file);
    }

    try {
      if (state?.type === "edit") {
        const res = await http.put("/content/update", data, {
          headers: {
            "Content-Type": "multipart/form-data", // กำหนดเป็น multipart/form-data เมื่อส่ง FormData
            Authorization: `Bearer ${localStorage.getItem("access-token")}`,
          },
        });
        if (res.status === 200) {
          await Swal.fire({
            title: "สำเร็จ!",
            text: "แก้ไขเรียบร้อยแล้ว",
            icon: "success",
          });
          navigate("/o2e-admin/journalsandblogs");
        }
      } else {
        const res = await http.post("/content/create", data, {
          headers: {
            "Content-Type": "multipart/form-data", // กำหนดเป็น multipart/form-data เมื่อส่ง FormData
            Authorization: `Bearer ${localStorage.getItem("access-token")}`,
          },
        });
        if (res.status === 201) {
          await Swal.fire({
            title: "สำเร็จ!",
            text: "สร้างเรียบร้อยแล้ว",
            icon: "success",
          });
          navigate("/o2e-admin/journalsandblogs");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = (id, name) => {
    if (name === "new") {
      const updatedImages = multiImageURL.filter((image) => image.id !== id);
      setMultiImageURL(updatedImages);
    } else {
      const updatedImages = oldMultiImageURL.filter((image) => image.id !== id);
      setOldMultiImageURL(updatedImages);
    }
  };

  return (
    <Container maxWidth="xl" className="mb-2 mt-3">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", padding: "20px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#AF9571",
              color: "#AF9571",
              height: 40,
              fontSize: 18,
              textTransform: "none",
            }}
            onClick={() => navigate("/o2e-admin/journalsandblogs")}
          >
            <p>ย้อนกลับ</p>
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {imageURL && (
          <img
            src={imageURL?.url}
            alt="Uploaded"
            style={{ maxWidth: "500px", maxHeight: "500px", margin: "auto" }}
          />
        )}
        {oldImageURL && (
          <img
            src={oldImageURL}
            alt="Uploaded"
            style={{ maxWidth: "500px", maxHeight: "500px", margin: "auto" }}
          />
        )}
        <br />
        {imageURL !== null || oldImageURL !== null ? (
          <Button
            variant="contained"
            color="error"
            component="span"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setImageURL(null);
              setOldImageURL(null);
            }}
            sx={{
              width: 200,
              margin: "auto",
            }}
          >
            ลบ
          </Button>
        ) : (
          <div>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                style={{ borderRadius: "50%", width: 200, height: 200 }}
              >
                Upload
              </Button>
            </label>
          </div>
        )}
      </div>
      <div className="mt-3">
        <TextField
          label="หัวข้อ"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <TextField
          label="รายละเอียด"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            variant="contained"
            sx={{
              height: 40,
              fontSize: 18,
              textAlign: "right",
            }}
            color="success"
            onClick={() => onCreate()}
          >
            <p>ยืนยัน</p>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default JournalsAndBlogsAdd;
