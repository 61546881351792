import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import img1 from "../../assets/blog4img12.png";
import img2 from "../../assets/blog4img13.png";
import img3 from "../../assets/blog4img14.png";
import img4 from "../../assets/blog4img15.png";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import img5 from "../../assets/blog4img6.png";
import img6 from "../../assets/blog4img7.png";
import img7 from "../../assets/blog4img8.png";
import Button from "@mui/material/Button";
import apiBlog from "../../api/blog";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
const Seemore2Blog4 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(
    `<h1>Baan Lae Suan Fair 2021</h1>\n<img src=\"https://png.pngtree.com/thumb_back/fh260/background/20210911/pngtree-xiaguang-daytime-rape-flower-mountain-no-photography-picture-with-picture-image_851488.jpg\" alt=\"undefined\" style=\"height: auto;width: auto\"/>\n<p></p>\n`,
  );
  useEffect(() => {
    apiBlog.getData().then((r) => setData(r.data?.elements[0]?.detail ? r.data?.elements[0]?.detail : "<p>topfy</p>"));
  }, []);
  return (
    <>
      <div>
        <Container maxWidth="lg" className="mb-2">
          <div dangerouslySetInnerHTML={{ __html: data }} />
          {/*<div*/}
          {/*  style={{ display: "flex", justifyContent: "center" }}*/}
          {/*  className="mb-4"*/}
          {/*>*/}
          {/*  <div style={{ width: "fit-content" }}>*/}
          {/*    <h1 style={{ marginBottom: 0 }}>Baan Lae Suan Fair 2021</h1>*/}
          {/*    <Divider*/}
          {/*      style={{*/}
          {/*        borderColor: "#AF9571",*/}
          {/*        borderWidth: 2,*/}
          {/*        borderRadius: 3,*/}
          {/*        width: 250,*/}
          {/*        margin: "auto",*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<Card sx={{}} className="m-auto">*/}
          {/*  <CardMedia component={"img"} image={img1} />*/}
          {/*</Card>*/}
          {/*<div className="text-left">*/}
          {/*  <p>19 September 2564</p>*/}
          {/*  <br />*/}
          {/*  <p>*/}
          {/*    BANGKOK — A popular home and garden decor fair is back, this time*/}
          {/*    aiming at Thais and foreigners struggling to navigate the*/}
          {/*    challenges of limited living spaces in Bangkok’s condos and small*/}
          {/*    homes.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    At Baanlaesuan Midyear Fair 2019, the highlights are markets*/}
          {/*    selling goods for every lifestyle and demonstrations of what an*/}
          {/*    ideal home might look like in the confines of city lives. There*/}
          {/*    are also workshops on a range of homeliving techniques, like how*/}
          {/*    to arrange a garden shed and clean a working desk.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    The fair also caters to expats and foreign visitors to Bangkok, as*/}
          {/*    evident in bilingual home decoration books being sold in the*/}
          {/*    booths and gardens that showcase both Western and Asian plants.*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<Card sx={{}} className="m-auto">*/}
          {/*  <CardMedia component={"img"} image={img2} />*/}
          {/*</Card>*/}
          {/*<div className="text-left">*/}
          {/*  <p>*/}
          {/*    The event is organized by a magazine of the same name (literally*/}
          {/*    “Homes and Gardens” in Thai), which has been a household name for*/}
          {/*    decor enthusiasts in Thailand for decades.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    “We tend to be fascinated by exotic things, things that are not*/}
          {/*    common in where we live,” fair director Ath Prapunwattana said in*/}
          {/*    an interview. “To Thais, we may be interested in English gardens,*/}
          {/*    but for Westerners, it’s tropical rainforests.”*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<Card sx={{}} className="m-auto">*/}
          {/*  <CardMedia component={"img"} image={img3} />*/}
          {/*</Card>*/}
          {/*<div className="text-left">*/}
          {/*  <p>*/}
          {/*    Walking and shopping at Baanlaesuan Midyear Fair 2019 is an*/}
          {/*    experience in itself. As usual, hordes of vendors selling*/}
          {/*    furniture of any imaginable style and function are back. Both*/}
          {/*    made-ready and pre-order goods are available. Pro tip from Ath:*/}
          {/*    spend the first day scanning the halls for idea first, then come*/}
          {/*    back another day to shop.*/}
          {/*  </p>*/}
          {/*</div>*/}

          {/*<Card sx={{}} className="m-auto">*/}
          {/*  <CardMedia component={"img"} image={img4} />*/}
          {/*</Card>*/}
          {/*<div className="text-left">*/}
          {/*  <p>*/}
          {/*    But it’s not just furniture either. There are two zones of craft*/}
          {/*    markets where visitors can browse through a diverse collection of*/}
          {/*    handmade trinkets, fridge magnets, home decors, leather goods and*/}
          {/*    other lifestyle items.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    There is also a food market with a wide range of cuisine and a*/}
          {/*    “Relax Zone” to catch a break with a cup of fresh coffee.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    To seek inspiration, take a peek at “Artisan Village,” which*/}
          {/*    provides guides on how working spaces for artisans can be*/}
          {/*    incorporated as parts of their homes, from coffee and papermaking*/}
          {/*    to ceramics and leather works.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    “For many people nowadays, a home needs to support functions of*/}
          {/*    their jobs,” Ath said. “The trend is to have a home where one can*/}
          {/*    pursue his or her craft.”*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    As an illustration, the room for paper making was designed to have*/}
          {/*    more ventilation and lighting, whereas leather workshop is in a*/}
          {/*    closed setting.*/}
          {/*    <br />*/}
          {/*    <br />*/}
          {/*    Those struggling to maintain orderly closets and work desks can*/}
          {/*    then head to “My Home” section to hear what steps can be taken to*/}
          {/*    clean out the mess – Marie Kondo styled – and learn about creative*/}
          {/*    use of limited spaces. For instance, the area beneath a bunk bed*/}
          {/*    can be used as a wardrobe, and believe it or not there are more*/}
          {/*    than one ways to arrange and hang your tools.*/}
          {/*  </p>*/}
          {/*</div>*/}
        </Container>

        <div style={{ backgroundColor: "#EFEFEF", marginTop: "40rem" }}>
          <Container>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="mb-4"
            >
              <div style={{ width: "fit-content" }}>
                <h1 style={{ marginBottom: 0 }}>Other Articles</h1>
                <Divider
                  style={{
                    borderColor: "#AF9571",
                    borderWidth: 2,
                    borderRadius: 3,
                    width: 150,
                    margin: "auto",
                  }}
                />
              </div>
            </div>
            <Grid container spacing={2} style={{ marginTop: "5rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <img
                    src={img5}
                    alt="Image 1"
                    style={{ width: "80%", marginBottom: "0px" }}
                  />
                  <p
                    style={{ fontSize: 15, margin: 0, marginLeft: "10%" }}
                    className="text-left"
                  >
                    <h4>Fall-inspired living room</h4>
                    If you’re looking to add some seasonal decor to your living
                    room this season, here are a few ideas to get you started.
                    Embrace the coziness of the season and enjoy the process!
                    Even one or two little changes can make an ....
                  </p>
                </Grid>

                <Grid item xs={4}>
                  <img
                    src={img6}
                    alt="Image 2"
                    style={{ width: "80%", marginBottom: "0px" }}
                  />
                  <p
                    style={{ fontSize: 15, margin: 0, marginLeft: "10%" }}
                    className="text-left"
                  >
                    <h4>Laminate flooring tips</h4>Installing laminate floors on
                    your own can save you a lot of money, and it’s definitely
                    the easiest flooring option for this project. The current
                    going rate for laminate flooring installation (as of Jan.
                    2019) is approxima....
                  </p>
                </Grid>

                <Grid item xs={4}>
                  <img
                    src={img7}
                    alt="Image 3"
                    style={{ width: "80%", marginBottom: "0px" }}
                  />
                  <p
                    style={{ fontSize: 15, margin: 0, marginLeft: "10%" }}
                    className="text-left"
                  >
                    <h4>Making a room look bigger</h4>Medium shades can make a
                    room appear smaller, but when you use bright whites and deep
                    dark paint colors, you create contrast, which creates depth
                    and the illusion of a larger space.
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <div className="mt-3">
              <Button
                variant="outlined"
                sx={{
                  margin: "auto",
                  borderColor: "#AF9571",
                  color: "#AF9571",
                  width: 300,
                  height: 60,
                  fontSize: 18,
                  textTransform: "none",
                  marginBottom: 5,
                }}
                onClick={() => navigate("/seemoreBlog4")}
              >
                <p>See All Journals & Blogs</p>
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Seemore2Blog4;
