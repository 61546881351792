import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import apiBlog from "../../../../api/blog";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Button from "@mui/material/Button";
import http from "../../../../api/http-common";
import Swal from "sweetalert2";

const JournalsAndBlogsAddSeeMore = () => {
  const navigate = useNavigate();
  const [detail, setDetail] = useState("");
  const [id, setID] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const callBack = async (file) => {
    const data = new FormData();
    data.append("file", file);
    const res = await http.post("/file/create", data, {
      headers: {
        "Content-Type": "multipart/form-data", // กำหนดเป็น multipart/form-data เมื่อส่ง FormData
        Authorization: `Bearer ${localStorage.getItem("access-token")}`,
      },
    });
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: res?.data.fileUrl,
        },
      });
    });
  };

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);

  const onSubmit = async () => {
    if (id) {
        onUpdate()
    } else {
        onCreate()
    }
  };

  const onCreate = async () => {
    const res = await apiBlog.create({
      detail: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    if (res.status === 200) {
      await Swal.fire({
        title: "สำเร็จ!",
        text: "เรียบร้อยแล้ว",
        icon: "success",
      });
    }
  };

  const onUpdate = async () => {
    const res = await apiBlog.update({
      id: id,
      detail: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    if (res.status === 200) {
      await Swal.fire({
        title: "สำเร็จ!",
        text: "เรียบร้อยแล้ว",
        icon: "success",
      });
    }
  };

  useEffect(() => {
    apiBlog.getData().then((r) => {
      setID(r.data?.elements[0]?.id ?? "");
      const html = r.data?.elements[0]?.detail;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    });
  }, []);
  return (
    <>
      <Container maxWidth="xl" className="mb-2 mt-3">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "left", padding: "20px 0px 20px 0px" }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#AF9571",
                color: "#AF9571",
                height: 40,
                fontSize: 18,
                textTransform: "none",
              }}
              onClick={() => navigate("/o2e-admin")}
            >
              <p>ย้อนกลับ</p>
            </Button>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #b1b7c1",
            borderRadius: "0.275rem",
          }}
        >
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={editorState}
            editorStyle={{ minHeight: "200px" }}
            onEditorStateChange={setEditorState}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: callBack,
                uploadImage: true,
                alt: { present: false, mandatory: false },
              },
            }}
          />
        </div>
        <div style={{ width: "100%", textAlign: "right" }} className="mt-3">
          <Button
            variant="contained"
            sx={{
              height: 40,
              fontSize: 18,
              textAlign: "right",
            }}
            color="success"
            onClick={() => onSubmit()}
          >
            <p>ยืนยัน</p>
          </Button>
        </div>
      </Container>
    </>
  );
};

export default JournalsAndBlogsAddSeeMore;
