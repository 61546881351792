import http from "./http-common";

const login = (data) => http.post(`/auth/login`, data);

const getUser = () => http.get(`/user`);

export default {
    login,
    getUser
};
