import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import apiProduct from "../../../api/product";
import http from "../../../api/http-common";
import Swal from "sweetalert2";
const ProductAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [imageURL, setImageURL] = useState(null);
  const [multiImageURL, setMultiImageURL] = useState([]);
  const [oldImageURL, setOldImageURL] = useState(null);
  const [oldMultiImageURL, setOldMultiImageURL] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [vendorItemNo, setVendorItemNo] = useState("");
  const [stock, setStock] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const state = location.state;

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (state?.type === "edit") {
      apiProduct.getDataById(state?.id).then((r) => {
        setOldImageURL(r.data.imageUrlProduct);
        setOldMultiImageURL(r.data.colorsDTOList);
        setDescription(r.data.detail);
        setPrice(r.data.price);
        setSize(r.data.size);
        setStock(r.data.stock);
        setTitle(r.data.title);
        setVendorItemNo(r.data.vendorItemNo);
      });
    }
  }, [state?.id]);
  const handleImageUploadMulti = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setMultiImageURL([
        ...multiImageURL,
        { id: multiImageURL.length, url: imageURL, file: file },
      ]);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setImageURL({ url: imageURL, file: file });
  };

  const onCreate = async () => {
    const data = new FormData();
    if (state?.type === "edit") {
      data.append("id", state?.id);
    }
    data.append("title", title);
    data.append("price", price);
    data.append("detail", description);
    data.append("size", size);
    data.append("vendorItemNo", vendorItemNo);
    data.append("stock", stock);

    if (oldImageURL) {
    } else {
      data.append("fileProduct", imageURL.file);
    }

    multiImageURL.forEach((url, index) => {
      data.append(`colorsDTOList[${index}].fileColors`, url.file);
    });

    oldMultiImageURL.forEach((url, index) => {
      const newIndex = multiImageURL.length + index;
      data.append(`colorsDTOList[${newIndex}].id`, url.id);
    });

    try {
      if (state?.type === "edit") {
        const res = await http.put("/product/update", data, {
          headers: {
            "Content-Type": "multipart/form-data", // กำหนดเป็น multipart/form-data เมื่อส่ง FormData
            Authorization: `Bearer ${localStorage.getItem("access-token")}`,
          },
        });
        if (res.status === 200) {
          await Swal.fire({
            title: "สำเร็จ!",
            text: "แก้ไขสินค้าเรียบร้อยแล้ว",
            icon: "success",
          });
          navigate("/o2e-admin/product");
        }
      } else {
        const res = await http.post("/product/create", data, {
          headers: {
            "Content-Type": "multipart/form-data", // กำหนดเป็น multipart/form-data เมื่อส่ง FormData
            Authorization: `Bearer ${localStorage.getItem("access-token")}`,
          },
        });
        if (res.status === 201) {
          await Swal.fire({
            title: "สำเร็จ!",
            text: "สร้างสินค้าเรียบร้อยแล้ว",
            icon: "success",
          });
          navigate("/o2e-admin/product");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteImage = (id, name) => {
    if (name === "new") {
      const updatedImages = multiImageURL.filter((image) => image.id !== id);
      setMultiImageURL(updatedImages);
    } else {
      const updatedImages = oldMultiImageURL.filter((image) => image.id !== id);
      setOldMultiImageURL(updatedImages);
    }
  };

  return (
    <Container maxWidth="xl" className="mb-2 mt-3">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", padding: "20px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#AF9571",
              color: "#AF9571",
              height: 40,
              fontSize: 18,
              textTransform: "none",
            }}
            onClick={() => navigate("/o2e-admin/product")}
          >
            <p>ย้อนกลับ</p>
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>ลายไม้</p>
        {imageURL && (
          <img
            src={imageURL?.url}
            alt="Uploaded"
            style={{ maxWidth: "500px", maxHeight: "500px", margin: "auto" }}
          />
        )}
        {oldImageURL && (
          <img
            src={oldImageURL}
            alt="Uploaded"
            style={{ maxWidth: "500px", maxHeight: "500px", margin: "auto" }}
          />
        )}
        <br />
        {imageURL !== null || oldImageURL !== null ? (
          <Button
            variant="contained"
            color="error"
            component="span"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setImageURL(null);
              setOldImageURL(null);
            }}
            sx={{
              width: 200,
              margin: "auto",
            }}
          >
            ลบ
          </Button>
        ) : (
          <div>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="outlined"
                color="primary"
                component="span"
                style={{ borderRadius: "50%", width: 200, height: 200 }}
              >
                Upload
              </Button>
            </label>
          </div>
        )}
      </div>
      <div className={"mt-5 text-left"}>
        <p>Colors</p>
        <div style={{ display: "flex" }}>
          {multiImageURL?.map((r, index) => (
            <div
              key={index}
              style={{ position: "relative", display: "inline-block" }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                src={r.url}
                alt="Uploaded"
                style={{ width: "100px", height: "100px", marginRight: 10 }}
              />
              {hoveredIndex === index && (
                <span
                  style={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    background: "rgba(255, 255, 255, 0.5)",
                    borderRadius: "50%",
                    padding: "4px",
                    width: 25,
                    height: 25,
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => handleDeleteImage(r.id, "new")}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </span>
              )}
            </div>
          ))}
          {oldMultiImageURL?.map((r, index) => (
            <div
              key={index}
              style={{ position: "relative", display: "inline-block" }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                src={r.imageUrlColors}
                alt="Uploaded"
                style={{ width: "100px", height: "100px", marginRight: 10 }}
              />
              {hoveredIndex === index && (
                <span
                  style={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    background: "rgba(255, 255, 255, 0.5)",
                    borderRadius: "50%",
                    padding: "4px",
                    width: 25,
                    height: 25,
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={() => handleDeleteImage(r.id, "old")}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </span>
              )}
            </div>
          ))}

          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file-multi"
            type="file"
            onChange={handleImageUploadMulti}
          />
          <label htmlFor="raised-button-file-multi">
            <Button
              variant="outlined"
              color="primary"
              component="span"
              style={{ width: 100, height: 100 }}
            >
              Upload
            </Button>
          </label>
        </div>
      </div>
      <div>
        <TextField
          label="หัวข้อ"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <TextField
          label="รายละเอียด"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
        <TextField
          label="ราคา"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
        />
        <TextField
          label="ขนาด"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setSize(e.target.value)}
          value={size}
        />
        <TextField
          label="จำนวน"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setStock(e.target.value)}
          value={stock}
        />
        <TextField
          label="Vendor item"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setVendorItemNo(e.target.value)}
          value={vendorItemNo}
        />
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            variant="contained"
            sx={{
              height: 40,
              fontSize: 18,
              textAlign: "right",
            }}
            color="success"
            onClick={() => onCreate()}
          >
            <p>ยืนยัน</p>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default ProductAdd;
