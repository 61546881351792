import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CardMedia from "@mui/material/CardMedia";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import apiContent from "../../../api/content";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

const JournalsAndBlogs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access-token") === null) {
      navigate("/login");
    }
  }, []);

  const fetchData = async () => {
    const res = await apiContent.getData();
    const dataWithIndex = res.data.elements.map((element, index) => ({
      ...element,
      order: index + 1, // เพิ่มลำดับเข้าไปในแต่ละองค์ประกอบของอาร์เรย์
    }));
    setData(dataWithIndex);
  };

  const handelDelete = async (id) => {
    try {
      const res = await apiContent.remove(id);
      if (res.status === 200) {
        await Swal.fire({
          title: "ลบสำเร็จ!",
          text: "ลบเรียบร้อยแล้ว",
          icon: "success",
        });
        await fetchData();
      } else {
        await Swal.fire({
          title: "ลบไม่สำเร็จ!",
          text: "ไม่สามารถลบได้",
          icon: "error",
        });
      }
    } catch (e) {
      console.log(e);
      await Swal.fire({
        title: "ลบไม่สำเร็จ!",
        text: "ไม่สามารถลบได้",
        icon: "error",
      });
    }
  };

  const columns = [
    { field: "order", headerName: "ลำดับ", width: 100 },
    { field: "title", headerName: "หัวข้อ", flex: 1 },
    { field: "description", headerName: "รายละเอียด", flex: 1 },
    {
      field: "imageUrlContent",
      headerName: "รูป",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        // <img src={params.value} alt="profile" style={{ width: 50, height: 50, borderRadius: '50%' }} />
        <CardMedia
          component={"img"}
          image={params.value}
          style={{
            height: 300,
            width: 300,
            margin: "auto",
          }}
        />
      ),
    },
    {
      field: "",
      headerName: "",
      width: 110,
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <div
            style={{
              backgroundColor: "#cfcaca",
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <DeleteIcon
              style={{ color: "red" }}
              sx={{
                fontSize: 30,
                cursor: "pointer",
              }}
              onClick={() => handelDelete(params.row.id)}
            />
          </div>
          <div
            style={{
              backgroundColor: "#cfcaca",
              width: 40,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              marginLeft: 10,
            }}
          >
            <EditIcon
              style={{ color: "yellow" }}
              sx={{
                fontSize: 30,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate("add", { state: { id: params.row.id, type: "edit" } })
              }
            />
          </div>
        </>
      ),
    },
  ];

  const getRowHeight = (params) => {
    return 300;
  };

  return (
    <Container maxWidth="xl" className="mb-2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", padding: "20px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#AF9571",
              color: "#AF9571",
              height: 40,
              fontSize: 18,
              textTransform: "none",
            }}
            onClick={() => navigate("/o2e-admin")}
          >
            <p>ย้อนกลับ</p>
          </Button>
        </div>
        <div style={{ textAlign: "right", padding: "20px 0px 20px 0px" }}>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#AF9571",
              color: "#AF9571",
              height: 40,
              fontSize: 18,
              textTransform: "none",
            }}
            onClick={() => navigate("add")}
          >
            <p>เพิ่ม</p>
          </Button>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          // checkboxSelection
          getRowHeight={getRowHeight}
        />
      </div>
    </Container>
  );
};

export default JournalsAndBlogs;
