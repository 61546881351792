import React, { useState } from "react";
// import pdf from "../../assets/pdf/pdfcat.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const Catalogue = () => {
  const pdfUrl = "https://www.africau.edu/images/default/sample.pdf";
  return (
    <div>
      {/*<Viewer fileUrl={pdf} plugins={[]} />*/}
    </div>
  );
};
export default Catalogue;
