import http from "./http-common";

const path = "/contact";
const getData = () => http.get(`${path}`);
const create = (data) => http.post(`${path}/create`, data);
const remove = (data) => http.delete(`${path}/${data}`);

export default {
    create,
    getData,
    remove,
};
