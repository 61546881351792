import React from "react";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import img1 from "../../assets/map1.png";
import icon1 from "../../assets/icon/icon1.png";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
const Map1 = () => {
  const position = "https://maps.app.goo.gl/dYBn8vTPzDJZ8teHA";

  return (
    <div style={{ width: 800 }} className="m-auto">
      <div
        className="mt-5"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
        }}
      >
        <div
          onClick={() => {
            window.open("https://maps.app.goo.gl/dYBn8vTPzDJZ8teHA");
          }}
          style={{ cursor: "pointer" }}
        >
          <CardMedia sx={{ height: 300 }} component={"img"} image={img1} />
        </div>
        <div className="m-auto">
          <div style={{ display: "flex" }}>
            <Avatar alt="Remy Sharp" src={icon1} />
            <p
              style={{
                marginTop: 5,
                marginLeft: 10,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              SHOWROOM - O2E SUPPLY
            </p>
          </div>
          <p className="text-left">
            Fyi Center Building (O2E Supply Company) <br />
            Building 2, G floor
            <br />
            2525 Rama IV Road
            <br />
            Khlong Tan Subdistrict, Khlong Toei District,
            <br />
            Bangkok 10110
          </p>
        </div>
      </div>
    </div>
  );
};

export default Map1;
