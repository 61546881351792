import React from "react";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import img1 from "../../assets/blog3img1.png";
import img2 from "../../assets/blog3img2.png";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
const Blog3 = () => {
  return (
    <>
      <div style={{ backgroundColor: "#FFF8F0" }} className="mt-5">
        <Container maxWidth="xl">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "fit-content" }}>
              <p className={"text-topic"} style={{ margin: 10 }}>
                What We Can Offer
              </p>
              <Divider
                style={{
                  borderColor: "#AF9571",
                  borderWidth: 2,
                  borderRadius: 3,
                  margin: "auto",
                  width: "85%",
                }}
              />
            </div>
          </div>
          <div
            className="mt-5"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <div
              style={{ position: "relative" }}
              className="m-auto pb-3 text-left"
            >
              <p>
                <h4>Services</h4>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic…Read more
              </p>
            </div>
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img1} />
              </Card>
            </div>
          </div>

          <div
            className="mt-5"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <div style={{ position: "relative" }} className="m-auto pb-3">
              <Card sx={{ maxWidth: 500 }}>
                <CardMedia component={"img"} image={img2} />
              </Card>
            </div>
            <div
              style={{ position: "relative" }}
              className="m-auto pb-3 text-left"
            >
              <p>
                <h4>Reliablility</h4>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry’s standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic…Read more
              </p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default Blog3;
